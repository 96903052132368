import React, { useState } from "react"
import {
  DataGrid, GridColDef, GridSelectionModel, GridToolbar,
} from "@mui/x-data-grid"
import { RiExpandUpDownLine } from "react-icons/ri"
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, } from "@mui/material"
import useAuth from "../../context/AuthProvider"
import moment from "moment"
import { ClientBillingReportModel } from "../../models/ClientBillingReportModel"
// import * as XLSX from 'xlsx'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2"
// import * as XLSX from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { style } from "@mui/system"
import formatPrice from "../../helper/currencyFormatter"
import { useLocation } from "react-router-dom"

interface Iprops {
  listData: ClientBillingReportModel[]
  onRemoveItems: (val: ClientBillingReportModel[]) => void
  setItemList: React.Dispatch<React.SetStateAction<ClientBillingReportModel[]>>
  selectedMonth: number
  handleFilterChange: any
  handleSortChange: any
  selectedPeriod: string
  selectedYear: string
}

export const ClientBillingReportMonthlyList: React.FC<Iprops> = ({
  listData,
  onRemoveItems,
  setItemList,
  selectedMonth,
  handleFilterChange,
  handleSortChange,
  selectedPeriod,
  selectedYear

}) => {
  const location = useLocation();
  const { user } = useAuth()
  const token = user?.token
  const [selectedRows, setSelectedRows] = useState<ClientBillingReportModel[]>()
  const [_pageSize, setPageSize] = React.useState(25);

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids)
    const selectedRowData = listData.filter(
      (row) => selectedIDs.has(row?.rowNum ?? "")
    )
    setSelectedRows(selectedRowData)
  }

  const handleDelete = () => {
    if (!selectedRows || selectedRows.length < 1) return
    onRemoveItems(selectedRows)
    setSelectedRows([])
  }



  // const printDataExcelwithoutLogo = () => {
  //   const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
  //   const groupedByClient = dataToPrint.reduce((acc, row) => {
  //     const client = row.partnerName;
  //     if (client) {
  //       if (!acc[client]) {
  //         acc[client] = [];
  //       }
  //       acc[client].push(row);
  //     }
  //     return acc;
  //   }, {} as Record<string, ClientBillingReportModel[]>);

  //   for (const [client, rows] of Object.entries(groupedByClient)) {
  //     const groupedData = rows.reduce((acc, row) => {
  //       const state = row.state;
  //       if (state) {
  //         if (!acc[state]) {
  //           acc[state] = [];
  //         }
  //         acc[state].push(row);
  //       }
  //       return acc;
  //     }, {} as Record<string, ClientBillingReportModel[]>);

  //     let grandTotalFeeDue = 0;
  //     const clientName = client || "Unknown Client";

  //     const wb = XLSX.utils.book_new();

  //     for (const [state, rows] of Object.entries(groupedData)) {
  //       const wsData: any[][] = [];
  //       const headerStyle = {
  //         font: { bold: true },
  //         fill: { fgColor: { rgb: 'CCCCCC' } } // Grey color for headers
  //       };
  //       const titleStyle = {
  //         font: { sz: 14, bold: true }, // Bigger font for "Filed Transactions"
  //         alignment: { horizontal: "center" }
  //       };

  //       // Adding custom header fields starting from the 6th row
  //       wsData[1] = [{ v: "Filed Transactions", s: titleStyle }];
  //       wsData[2] = ["Client Name:", clientName];
  //       wsData[3] = ["Start Date:", moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")];
  //       wsData[4] = ["End Date:", moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")];
  //       wsData[5] = []; // Empty row for spacing

  //       // Adding data headers
  //       const headers = Columns.filter(col => !col.hide).map(col => col.headerName);
  //       wsData[6] = headers.map(header => ({ v: header, s: headerStyle }));

  //       rows.forEach((row, rowIndex) => {
  //         const rowData: any[] = [];
  //         Columns.forEach((col) => {
  //           if (!col.hide) {
  //             let value = (row as any)[col.field];
  //             if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
  //               rowData.push({ v: value ? "✔" : "" });
  //             } else if (col.field === 'period') {
  //               const formattedMonth = value ? moment(value).format("MMMM") : '';
  //               rowData.push({ v: formattedMonth });
  //             } else {
  //               rowData.push({ v: value !== (undefined || null) ? value : "" });
  //             }

  //             if (col.field === 'feeDue') {
  //               grandTotalFeeDue += parseFloat(value) || 0;
  //             }
  //           }
  //         });
  //         wsData[7 + rowIndex] = rowData;
  //       });

  //       wsData.push(['', '', '', '', '', '', `Grand Total Fee Due`, grandTotalFeeDue.toFixed(2)]);

  //       const ws = XLSX.utils.aoa_to_sheet(wsData);

  //       // Apply striped table formatting
  //       const range = XLSX.utils.decode_range(ws['!ref'] || '');
  //       const evenRowStyle = { fill: { patternType: "solid", fgColor: { rgb: "FFDDDDDD" } } };
  //       const oddRowStyle = { fill: { patternType: "solid", fgColor: { rgb: "FFFFFFFF" } } };

  //       for (let R = range.s.r + 6; R <= range.e.r; ++R) {
  //         for (let C = range.s.c; C <= range.e.c; ++C) {
  //           const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
  //           if (!ws[cellRef]) continue; // Skip empty cells

  //           // Apply even or odd row style
  //           const isEvenRow = (R % 2) === 0;
  //           if (!ws[cellRef].s) ws[cellRef].s = {};
  //           ws[cellRef].s.fill = isEvenRow ? evenRowStyle.fill : oddRowStyle.fill;
  //         }
  //       }

  //       // Auto-adjust column widths
  //       const colWidths = headers.map((_, colIndex) => {
  //         let maxWidth = 10; // Minimum column width
  //         wsData.forEach(row => {
  //           const cell = row[colIndex];
  //           if (cell && cell.v !== undefined && cell.v !== null) {
  //             const cellValue = cell.v.toString();
  //             if (cellValue.length > maxWidth) {
  //               maxWidth = cellValue.length;
  //             }
  //           }
  //         });
  //         return { wch: maxWidth };
  //       });
  //       ws['!cols'] = colWidths;

  //       XLSX.utils.book_append_sheet(wb, ws, state);
  //     }

  //     XLSX.writeFile(wb, 'Report.xlsx');
  //   }
  // };

  // Function to convert an image to a base64 string
  const getBase64ImageFromUrl = (url: string) => {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
      img.src = url;
    });
  };

  const printDataExcel33 = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, ClientBillingReportModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      const workbook = new ExcelJS.Workbook();

      for (const [state, rows] of Object.entries(groupedData)) {
        const worksheet = workbook.addWorksheet(state);



        // Add custom header fields starting from the 6th row
        worksheet.mergeCells('B2:C2');
        worksheet.getCell('B2').value = "Filed Transactions";
        worksheet.getCell('B2').font = { size: 20 };

        // worksheet.getCell('B2').alignment = { horizontal: 'center' };

        // worksheet.getCell('A7').value = "Client Name:";
        worksheet.getCell('B3').value = clientName;
        worksheet.getCell('B3').font = { size: 15 };
        worksheet.getCell('B4').value = `Start Date:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`;

        worksheet.getCell('B4').value = `End Date:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`;


        // Adding data headers
        const headers = Columns.filter(col => !col.hide).map(col => col.headerName);
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCCC' }
          };
        });

        headers.forEach((header, index) => {
          worksheet.getColumn(index + 1).width = Math.max(header?.length ?? 10, 15); // Initialize column widths
        });

        rows.forEach((row) => {
          const rowData: any[] = [];
          Columns.forEach((col, colIndex) => {
            if (!col.hide) {
              let value = (row as any)[col.field];
              if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                value = value ? "✔" : "";
              } else if (col.field === 'period') {
                value = value ? moment(value).format("MMMM") : '';
              } else {
                value = value !== (undefined || null) ? value : "";
              }

              rowData.push(value);

              // Update column width
              if (value && value.toString().length > (worksheet.getColumn(colIndex + 1).width ?? 15)) {
                worksheet.getColumn(colIndex + 1).width = value.toString().length;
              }

              if (col.field === 'feeDue') {
                grandTotalFeeDue += parseFloat(value) || 0;
              }
            }
          });
          worksheet.addRow(rowData);
        });
        worksheet.getCell(`G` + (rows.length + 6)).value = `Fee Due: `;
        worksheet.getCell(`G` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        worksheet.getCell(`H` + (rows.length + 6)).value = grandTotalFeeDue.toFixed(2);
        worksheet.getCell(`H` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        // Apply striped table formatting
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 10) { // Apply styles to data rows
            row.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: rowNumber % 2 === 0 ? 'FFDDDDDD' : 'FFFFFFFF' }
              };
            });
          }
        });


        // Add image to worksheet
        const base64Image = await getBase64ImageFromUrl('/assets/brand/ExcelLogo.png'); // Replace with your image path
        const imageId = workbook.addImage({
          base64: base64Image.split(',')[1], // Extract base64 data
          extension: 'png',
        });
        worksheet.addImage(imageId, 'A1:A2');


      }

      // Convert the workbook to a Blob
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Use FileSaver.js to save the file
      saveAs(blob, 'Report.xlsx');
    }
  };

  const printDataExcelOld = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, ClientBillingReportModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      for (const [state, rows] of Object.entries(groupedData)) {
        // Load the existing template

        const workbook = new ExcelJS.Workbook();
        const response = rows.length > 19 ? (await fetch('/assets/brand/ClientBillingTemplateNew1.xlsx')) : await fetch('/assets/brand/ClientBillingTemplateNew.xlsx'); // Replace with your template path
        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.getWorksheet(1);

        if (worksheet != null) {
          worksheet.getCell('B3').value = clientName;
          worksheet.getCell('B4').value = `Star tDate:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`
          worksheet.getCell('B5').value = `EndDate:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`



          // add a table to a sheet

          rows.forEach((row, rowIndex) => {


            Columns.forEach((col, colIndex) => {

              if (!col.hide) {
                let value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                  value = value ? "✔" : "";
                } else if (col.field === 'period') {
                  value = value ? moment(value).format("MMMM") : '';
                }
                else {
                  value = value !== (undefined || null) ? value : "";
                }
                if (col.field === 'feeDue') {
                  grandTotalFeeDue += parseFloat(value) || 0;
                }

                worksheet.getRow(rowIndex + 7).getCell(colIndex).value = value;

                // worksheet.getRow(rowIndex + 7).getCell(colIndex). style =  {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: '#ffa5a5a5'}}}

              }

            });

            // worksheet.addRow(rowData);
          });
          if (rows.length > 19) {
            worksheet.getCell(`G` + (rows.length + 8)).value = 'Fee Due';
            worksheet.getCell(`G` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            worksheet.getCell(`H` + (rows.length + 8)).value = grandTotalFeeDue.toFixed(2);
            worksheet.getCell(`H` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            // worksheet.getCell(`H` + (rows.length + 6)).fill = { bgColor:{ argb: "#ffffff" } , pattern : 'solid'  , type: 'pattern', };
          }



        } else {
          alert("Something went wrong");
          return;
        }

        // Convert the workbook to a Blob
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Use FileSaver.js to save the file
        saveAs(blob, 'Report.xlsx');
      }
    }
  };

  const printDataExcel = async () => {
    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    
    // Group by client first
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) acc[client] = [];
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);
    
    let grandTotalFeeDue = 0;
  
    try {
      const workbook = new ExcelJS.Workbook();
      // const response = await fetch('/assets/brand/ClientBillingTemplateNewUpdated_new.xlsx');
      const response = await fetch('/assets/brand/ClientBillingTemplateNewUpdated_new_new.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) return alert("Something went wrong");
  
      // let rowIndex = 4;
      let rowIndex = 3;
      let filteredColumns = Columns.filter(col => col.headerName && col.headerName !== 'No' && col.headerName !== 'startDate' && col.headerName !== 'endDate');
      const stateColumnIndex = filteredColumns.findIndex(col => col.headerName === 'state');
      if (stateColumnIndex !== -1) filteredColumns.unshift(filteredColumns.splice(stateColumnIndex, 1)[0]);
      const columnHeaders = filteredColumns.map(col => col.headerName);
  
      const addHeaderRow = (rowIdx: number) => {
        const headerRow = worksheet.getRow(rowIdx);
        columnHeaders.forEach((header, colIndex) => {
          const cell = headerRow.getCell(colIndex + 1);
          cell.value = header;
          cell.font = { bold: true };
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '808080' } };
        });
        return rowIdx + 1;
      };
  
      // Only process the first client
      const [firstClientName, firstClientRows] = Object.entries(groupedByClient)[0] || [];
    
      if (firstClientName && firstClientRows) {
        // Group rows by state for the first client
        const groupedByState = firstClientRows.reduce((acc, row) => {
          const state = row.state; // Assuming `state` exists in your data
          if (state) {
            if (!acc[state]) acc[state] = [];
            acc[state].push(row);
          }
          return acc;
        }, {} as Record<string, ClientBillingReportModel[]>);
    
        let clientTotalFeeDue = 0;
        worksheet.getRow(rowIndex).getCell(2).value = firstClientName;
        worksheet.getRow(rowIndex).getCell(2).font = { bold: false, size: 14 };
        rowIndex++;
        // Insert Start Date into column B
        // const startDate = moment(firstClientRows[0].startDate).format('MM/DD/YYYY'); // Use the first row's startDate
        worksheet.getRow(rowIndex).getCell(2).value = `Start Date: ${selectedPeriod === 'Monthly' 
          ? moment(new Date(parseInt(selectedYear, 10), selectedMonth - 1, 1)).format("MM/DD/YYYY") 
          : moment(new Date(parseInt(selectedYear, 10), 0, 1)).format("MM/DD/YYYY")}`;
          worksheet.getRow(rowIndex).getCell(2).font = { bold: false };
        
          rowIndex++;
          
          // Set End Date
          worksheet.getRow(rowIndex).getCell(2).value = `End Date: ${selectedPeriod === 'Monthly' 
              ? moment(new Date(parseInt(selectedYear, 10), selectedMonth, 0)).format("MM/DD/YYYY") 
              : (parseInt(selectedYear, 10) === new Date().getFullYear()
                  ? moment(new Date()).format("MM/DD/YYYY")
                  : moment(new Date(parseInt(selectedYear, 10), 11, 31)).format("MM/DD/YYYY"))}`;
          worksheet.getRow(rowIndex).getCell(2).font = { bold: false };
          
          rowIndex++;
    
        // Loop through each state and print its data
        for (const [stateName, rows] of Object.entries(groupedByState)) {
          // worksheet.getRow(rowIndex).getCell(1).value = `State: ${stateName}`;
          // worksheet.getRow(rowIndex).font = { bold: true, size: 12 };
          // rowIndex++;
          rowIndex = addHeaderRow(rowIndex);
  
          rows.forEach((row) => {
            filteredColumns.forEach((col, colIndex) => {
              if (!col.hide) {
                let value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") value = value ? "✔️" : "";
                else if (col.field === 'period') value = value ? moment(value).format("MMMM") : '';
                else if (col.field === 'dateFinal') value = value ? moment(value).format("MM/DD/YYYY") : '';
                else value = value !== (undefined || null) ? value : "";
                if (col.field === 'feeDue') {
                  const fee = parseFloat(value) || 0;
                  clientTotalFeeDue += fee;
                  grandTotalFeeDue += fee;
                }
                const cell = worksheet.getRow(rowIndex).getCell(colIndex + 1);
                cell.value = col.field === 'feeDue' ? Number(value) : value;
                if (col.field === 'feeDue') cell.numFmt = '"$"#,##0.00';
                const fillColors = ['d9d9d9', 'FFFFFF', 'ededed'];
                const fillColor = fillColors[(rowIndex - 9) % 3];
                if (fillColor !== 'FFFFFF') cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
              }
            });
            rowIndex++;
          });
  
          // worksheet.getCell(`G${rowIndex}`).value = `Total Fee Due for ${stateName}`;
          // worksheet.getCell(`G${rowIndex}`).font = { bold: true, color: { argb: "FF0000" } };
          // worksheet.getCell(`H${rowIndex}`).value = clientTotalFeeDue;
          // worksheet.getCell(`H${rowIndex}`).numFmt = '"$"#,##0.00';
          rowIndex++;
        }
      }
    
      worksheet.getCell(`G${rowIndex}`).value = 'Total Fee Due';
      worksheet.getCell(`G${rowIndex}`).font = { bold: true, size: 15, color: { argb: "FF0000" } };
      worksheet.getCell(`H${rowIndex}`).value = grandTotalFeeDue;
      worksheet.getCell(`H${rowIndex}`).numFmt = '"$"#,##0.00';
      worksheet.getCell(`H${rowIndex}`).font = { bold: true, size: 15, color: { argb: "FF0000" } };
  
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'ClientBillingReport.xlsx');
    } catch (error) {
      console.error("Error generating the Excel file:", error);
    }
  };
  
  

  const printDataPdf = (isMonthEndBilling: boolean = false) => {
    const dataToPrint = isMonthEndBilling ? listData : (selectedRows && selectedRows.length > 0 ? selectedRows : listData);
    
    // const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
        const groupedData = rows.reduce((acc, row) => {
            const state = row.state;
            if (state) {
                if (!acc[state]) {
                    acc[state] = [];
                }
                acc[state].push(row);
            }
            return acc;
        }, {} as Record<string, ClientBillingReportModel[]>);

        let grandTotalFeeDue = 0;
        const printWindow = window.open("", "_blank");

        if (printWindow) {
            const clientName = client;
            // Add the logo using an img tag
            printWindow.document.write(`
            <html>
                <head>
                    <title>Print Report</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                        }
                        .header {
                            text-align: left;
                            margin-bottom: 20px;
                        }
                        .header h1 {
                            margin: 0;
                            font-size: 24px;
                        }
                        .header p {
                            margin: 0;
                            font-size: 14px;
                        }
                        table {
                            width: 100%;
                            margin-bottom: 20px;
                            border-collapse: collapse;
                            table-layout: fixed;
                        }
                        th, td {
                            text-align: left;
                            padding: 8px;
                            border: none;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        th {
                            background-color: grey;
                            color: white;
                            text-align: left;
                            padding: 12px;
                            white-space: nowrap;
                        }
                        tr.header-row th {
                            background-color: grey;
                        }
                        tbody tr:nth-child(even) {
                            background-color: #f2f2f2;
                        }
                        tbody tr:nth-child(odd) {
                            background-color: #ffffff;
                        }
                        .fee-due {
                            color: black;
                            font-weight: normal;
                        }
                        .total-row {
                            font-weight: bold;
                        }
                        @media print {
                            th {
                                background-color: grey !important;
                                -webkit-print-color-adjust: exact;
                            }
                        }
                    </style>
                </head>
                <body>
                  <div class="header" style="margin-bottom: 7px">
                      <div style="display: flex; align-items: center;">
                          <img src="/assets/brand/ExcelLogo.png" alt="Logo" style="height:60px; width:70px; margin-right: 5px;">
                          <h1 style="font-size: 30px; font-family: 'Calibri Light', 'Gill Sans', 'Open Sans', Geneva, Helvetica, Arial, sans-serif; font-weight: 1000;">Filed Transactions</h1>
                      </div>
                      <h4 style="font-weight: lighter; margin:0 0 0 70px; font-size: 20px;">${clientName}</h4>
                      <div class="date-container" style="margin-top: 10px; margin-left: 70px;">
                          <p style="margin: 0">
                              Start Date: 
                              ${selectedPeriod === 'Monthly' 
                                  ? moment(new Date(parseInt(selectedYear, 10), selectedMonth - 1, 1)).format("MM/DD/YYYY") 
                                  : moment(new Date(parseInt(selectedYear, 10), 0, 1)).format("MM/DD/YYYY")}
                          </p>
                          <p style="margin-top: 10px;">
                              End Date: 
                              ${selectedPeriod === 'Monthly' 
                                  ? moment(new Date(parseInt(selectedYear, 10), selectedMonth, 0)).format("MM/DD/YYYY") 
                                  : (parseInt(selectedYear, 10) === new Date().getFullYear()
                                      ? moment(new Date()).format("MM/DD/YYYY")
                                      : moment(new Date(parseInt(selectedYear, 10), 11, 31)).format("MM/DD/YYYY"))}
                          </p>
                      </div>
                  </div>
            `);

            for (const [state, rows] of Object.entries(groupedData)) {
                printWindow.document.write(`
                    <table>
                        <thead>
                            <tr class="header-row">
                `);
                Columns.forEach((col) => {
                    if (!col.hide) {
                        printWindow.document.write(`<th>${col.headerName}</th>`);
                    }
                });
                printWindow.document.write(`
                            </tr>
                        </thead>
                        <tbody>
                `);

                rows.forEach((row) => {
                    printWindow.document.write("<tr>");
                    Columns.forEach((col) => {
                        if (!col.hide) {
                            const value = (row as any)[col.field];
                            let formattedValue = value !== (undefined || null) ? value : "";

                            if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                                formattedValue = value ? "✔️" : "";
                            } else if (col.field === "period") {
                                formattedValue = value ? moment(value).format("MMMM") : "";
                            } else if (col.field === "feeDue") {
                              formattedValue= formattedValue === "" ? '0.00':formattedValue; 
                                printWindow.document.write(`<td class="fee-due">${"$" + formattedValue}</td>`);
                                grandTotalFeeDue += parseFloat(value) || 0;
                            } else if (col.field === "dateFinal") {
                              formattedValue = value ? moment(value).format("MM/DD/YYYY") : "";
                              printWindow.document.write(`<td>${formattedValue}</td>`);
                            } 
                            else {
                                printWindow.document.write(`<td>${formattedValue}</td>`);
                            }
                        }
                    });
                    printWindow.document.write("</tr>");
                });

                printWindow.document.write(`
                        </tbody>
                    </table>
                `);
            }

            printWindow.document.write(`
                <table> 
                    <tfoot>
                        <tr class="total-row">
                            <td colspan='${Columns.length - 1}' class="fee-due" style='text-align: right; color: red; font-weight: bold;'>Fees Due</td>
                            <td class="fee-due" style=' color: red; font-weight: bold;'>${"$" + grandTotalFeeDue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                        </tr>
                    </tfoot>
                </table>
            </body>
            </html>
            `);
            printWindow.document.close();
            printWindow.print();
        }
    }
};

  
  
  
  
  
  
  
  const [selectedOption, setSelectedOption] = useState<string>('Print');
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
    printData(event.target.value);

  };

  const printData = (option: string, isMonthEndBilling: boolean = false) => {
    // if (isMonthEndBilling === false){
    //   if (!selectedRows || selectedRows.length === 0) {
    //     Swal.fire({
    //       icon: 'warning',
    //       title: 'No rows selected',
    //       text: 'Please select at least one row to proceed.',
    //     });
    //     setSelectedOption('0');
    //     return;
    //   }
    // }

    if (option === 'excel') {
      printDataExcel();
    } else if (option === 'pdf') {
      // printDataPdf(true);
      isMonthEndBilling ? printDataPdf(true) : printDataPdf();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No option selected',
        text: 'Please select an option to proceed.',
      });
    }
    setSelectedOption('0');
  };
  
  function EditToolbar() {
    const isMonthEndBillingPage = location.pathname === '/Billing/MonthEndBillingModule';
    const [apiResponse, setApiResponse] = useState(null); // To store the API response

    // Function to handle API call
    const handleMonthEndBillingClick = async () => {
      printData('pdf', true);
    };
    return (
      <React.Fragment>
        <Box display="flex" sx={{}}>
          <Grid
            container
            display="flex"
            margin={"10px 0px"}
            justifyContent={"space-between"}
          >

            <Grid item xs={12} md={6}>
              <GridToolbar />
            </Grid>
            <Grid item xs={12} md={6}>

              <Grid container display='flex' justifyContent={'flex-end'}>
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className="select-input-border">
                  {isMonthEndBillingPage && user?.userRoles.split(",").includes("Super Admin") && (
                    <button className="active-btn-blue" style = {{padding: 1,  maxHeight: 45, height: 40}}  onClick={handleMonthEndBillingClick}>
                      Month End Billing
                    </button>
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">
                  {user?.userRoles.split(",").includes("Super Admin") ? (
                    <button
                      onClick={handleDelete}
                     className="active-btn-blue" 
                     style = {{padding: 1,  maxHeight: 45, height: 40}}
                    >
                      Remove
                    </button>
                  ) : (
                    ""
                  )}

                </Grid>
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">

                  {user?.userRoles.split(",").includes("Super Admin") ? (

                    <FormControl fullWidth className="">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="active-btn-print"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        style={{margin: 0}}
                        renderValue={(selected) => {
                          if (selected === '0') {
                            return 'Print';
                          }
                          return selected;
                        }}
                      >
                        {/* <MenuItem key={0} value={0}> {'Print'} </MenuItem> */}
                        <MenuItem value={'excel'}>Excel</MenuItem>
                        <MenuItem value={'pdf'}>PDF</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Grid>


              </Grid>

            </Grid>
          </Grid>
        </Box>

      </React.Fragment>
    );
  }
  const Columns: GridColDef[] = [
    {
      field: 'rowNum',
      type: 'number',
      headerName: 'No',
      hide: true,
      hideable: false,
      disableExport: true,
    },

    {
      field: "state",
      type: "text",
      headerName: "State",
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "invoiceNumber",
      type: "text",
      headerName: "Invoice Number",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "insuredName",
      type: "text",
      headerName: "Insured Name",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "policyNumber",
      type: "text",
      headerName: "Policy Number",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "filed",
      type: "text",
      headerName: "Status",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "dateFinal",
      type: "text",
      headerName: "Date Filed",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        const dateValue = params.value;
    
        if (dateValue) {
          const date = new Date(dateValue);
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
    
          return `${month}/${day}/${year}`;
        }
        
        return "";
      },
      // valueGetter: (params) => params.value,
    },
    {
      field: "transactionTypeName",
      type: "text",
      headerName: "Transaction Type",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "feeDue",
      type: "text",
      headerName: "Fee Due",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => formatPrice(params.value),
      editable: true, // Make the feeDue column editable
    },
  ];



  const handleRowEditCommit = (row: any) => {

    let updatedRow = row.row
    let AllRec = [...listData];

    let exsistinfRec = AllRec.find(x => x.rowNum === updatedRow.rowNum);

    if (exsistinfRec != undefined) {
      exsistinfRec.feeDue = updatedRow.feeDue;
      setItemList(AllRec);
    }

    return updatedRow;
  };

  

  return (

    <DataGrid
      getRowId={(r) => r.rowNum}
      rows={listData}
      columns={Columns}
      pageSize={_pageSize}
      pagination
      rowsPerPageOptions={[10, 25, 50]}
      editMode="row"
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      onRowEditStop={handleRowEditCommit}
      checkboxSelection={
        user?.userRoles.split(",").includes("Super Admin") ? true : false
      }
      filterMode="server"
      onFilterModelChange={handleFilterChange} // For filtering
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSortChange} // Add this line for sorting
      onSelectionModelChange={
        user?.userRoles.split(",").includes("Super Admin")
          ? (newRowSelectionModel) => {
            onRowsSelectionHandler(newRowSelectionModel)
          }
          : undefined
      }
      components={{
        Toolbar: EditToolbar,
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        fontFamily: "'Proxima Nova', sans-serif",
        border: "0px",
        fontSize: "16px",
      }}
    // experimentalFeatures={{ newEditingApi: true }}
    />
  )
}
