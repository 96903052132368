import { useEffect, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { TaxTransactionReportModel, TaxTransactionReportModel_Resp } from "../models/TaxTransactionReportModel";
import { Box, Stack, width } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { TaxTransactionReportList } from "../components/Lists/TaxTransactionReportList";
import { writeXlsxFile } from "../helper/utils";
//import { FieldAttributes, useField, useFormikContext } from "formik";
import { Field, FieldArray, FieldAttributes, Form, Formik, useField, useFormik, useFormikContext } from 'formik';
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DiligentSearchList } from "../components/Lists/DiligentSearchList";
import Swal from "sweetalert2";
import DiligentMarkCompleted from "./DiligentMarkCompleted";
import moment from "moment";

export const DiligentSearch = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: TaxTransactionReportModel_Resp = {
        getOutstandingFilingsReport_Respdata: [],
        totalCount: 0,
        needDiligentSearch: undefined,
        diligentSearchCompleted: undefined
    }

    const [ReportModule, setReportModule] = useState<TaxTransactionReportModel_Resp>(initValue)
    const [value, setValue] = React.useState(8);
    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const [selectedButton, setSelectedButton] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<TaxTransactionReportModel[]>()
    const [openCompletedDialog, setOpenCompletedDialog] = useState<string>('');
    const [completedPage, setCompletedPage] = useState<boolean>(false)
    const [_currentPage, setCurrentPage] = React.useState(1);
    const [_pageSize, setPageSize] = React.useState(50);
    

    const keysNamesFromXlsx = [
        'HomeState', 'InsuredName', 'PolicyNumber', 'InvoiceNumber',
      'TransactionType', 'Carrier', 'NAIC','PolicyEffDate', 'PolicyExpDate',
       'GrossPremium','Client', 'Suspended', 'NeedDiligentSearch', 'DiligentSearchNotes',
        'DiligentSearchCompleted', 'DateCompleted'
    ]

    const keyNames: (keyof TaxTransactionReportModel)[] = [
        'homeState', 'insuredName', 'policyNumber', 'invoiceNumber',
       'transactionType',  'carrier', 'naic','policyEffDate', 'policyExpDate', 
       'grossPremium', 'client', 'suspended', 'needDiligentSearch', 'diligentSearchNotes',
        'diligentSearchCompleted', 'dateCompleted'
    ]

    const handleOpenCompletedDialog = (status: string) => {
        setOpenCompletedDialog(status);
    };

    const handleCloseCompletedDialog = () => {
        setOpenCompletedDialog('');
    };

    
    const handleSubmitCompletedDialog = (completedDate: string) => {
        setOpenCompletedDialog('');
        setloading(true);
        var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
        var Ids = customerPolicyIds.join(',')
        fetch(config.url.API_URL + `/DiligentSearchReport/updateDiligentSearchCompleted?dateCompleted=${completedDate}&ids=${Ids}`, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
        })
        .then((res) => {
            setSelectedRows([]);
            Swal.fire({
                title: "Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            })
           
            fetchNewData('getOutstandingDiligentSearchReportData', _pageSize, _currentPage, completedPage);
            setloading(false);
            })
        .catch((error) => {
            console.log(error);
            setloading(false);
        });
        

    };

    const fetchNewData = (action: string, pageSize: number, pageNumber: number, isCompletedPage: boolean) => {
        setloading(true);
        setSelectedRows([]);

        const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
            let start: Date = new Date(effectiveDateSt.current);
            effectiveDateSt.current = start.toLocaleDateString('en-US', options);
        }

        if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
            let end: Date = new Date(effectiveDateEd.current ?? '');
            effectiveDateEd.current = end.toLocaleDateString('en-US', options);

        }

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        fetch(
            config.url.API_URL +
            `/DiligentSearchReport/${action}?userId=${user?.userId}&&policyNumber=${PloicyNum.current}&&stateId=${stateData.current}
            &&effectiveDateStart=${effectiveDateSt.current}&&effectiveDateEnd=${effectiveDateEd.current} 
            &&insuredName=${insName.current}&&completedPage=${isCompletedPage}&&customerId=${customerData.current}&&customerIds=${customerIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                // Do something with the response
                if (res.data?.totalCount > 0) {
                    setReportModule(res.data)
                    console.log("data",res)
                }
                else {
                    setReportModule(initValue)
                }
                
            }).catch((error) => {
                setloading(false)
                console.log(error)
            });
    }

    const handleChange = (
        event: React.SyntheticEvent | undefined,
        newValue: number
    ) => {
        setCompletedPage(newValue == 2)
        setloading(true);
        setSelectedButton(newValue);
        const action = "getOutstandingDiligentSearchReportData";
        fetchNewData(action, 50, 1, newValue == 2);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const onExportXlsx = (values: TaxTransactionReportModel[]) => {
        setloading(true);
        if (values.length === 0) {
            return
        }
        let filteredKeyNames = keyNames;
        if (selectedButton === 1) {
            filteredKeyNames = keyNames.filter(key => key !== 'dateCompleted' && key !== 'diligentSearchCompleted');
        }
        const items = values.map(item => {
            const update: (string | number | Date | undefined)[] = [];
    
            filteredKeyNames.map((key: keyof TaxTransactionReportModel) => {
                if (key === 'dateCompleted') {
                    update.push(moment.utc(item[key]).format("MM/DD/YYYY"));
                } else {
                    update.push(item[key]);
                }
            });
    
            return update;
        });
        const updates = [
            filteredKeyNames,
            ...items
        ]
        writeXlsxFile(updates)
        setloading(false);
    }
   
    const handleExportXlsxNew = async (val: TaxTransactionReportModel[]) => {
        try {
            setloading(true);

            const action = "getOutstandingDiligentSearchReportData";

            const response = await fetchExportData(action, selectedOptions, ReportModule?.totalCount, 1, completedPage);
            let values = response
            if (val && val.length > 0) {
                values = val
            }
            if (values.length <= 0) {
                alert('Please upload a xlsx file')
                return
            }
            onExportXlsx(values);
            Swal.fire({
                title: "Export Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            });
            setSelectedRows([]);
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred:", error);
        }
    }
    const handleDataChange = async (e: string) => {
        var result = selectedOptions.toLocaleString().split(',').filter((item) => item !== e);
        setSelectedOption(result);
        onChangeCustomer(result);
    }

    async function onChangeCustomer(customers: string | string[]) {
        const action = "getOutstandingDiligentSearchReportData";
        await fetchExportData(action, customers, 50, 1, completedPage);
    }
    async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number, isCompletedPage: boolean) {
        setloading(true);
        const selectedValuesArray = Array.isArray(customers) ? customers : [customers];
        var customerIds = selectedValuesArray.join(',');

        const response = await fetch(
            config.url.API_URL +
            `/DiligentSearchReport/${action}?userId=${user?.userId}&&policyNumber=&&stateId=0
            &&effectiveDateStart=&&completedPage=${isCompletedPage}&&effectiveDateEnd=
            &&insuredName=&&customerId=0&&customerIds=${customerIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                console.log("reportlistdata", res.data);
                if (res.data?.totalCount > 0) {

                    if (pageSize <= 100) {
                        setReportModule(res.data)
                    }

                    //setReportModule(res.data)
                    //setExportData(res.data.getOutstandingFilingsReport_Respdata);
                    return res.data.getOutstandingFilingsReport_Respdata;
                }
                else {
                    //setReportModule(initValue)
                    setReportModule(initValue)
                }
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });
        return response;
    }
    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getPartnersByDiligent", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                setCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);

    const FMSelectCustomer: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {

        return (
            <FormControl fullWidth sx={{}}  >
                <InputLabel id="State-Select-Label">Customers</InputLabel>
                <Select
                    // {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    label={"Customers"}

                    className="border-round main-form-2"
                    multiple
                    labelId="State-Select-Label"
                    value={selectedOptions}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => {
                        const selectedValues = e.target.value;
                        setSelectedOption(selectedValues);
                        onChangeCustomer(selectedValues);
                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip

                                    key={value}
                                    label={Customer.find(x => x.partnersId.toString() === value)?.customerName}
                                    onDelete={() =>
                                        // setSelectedOption(
                                        //     selectedOptions.toLocaleString().split(',').filter((item) => item !== value)
                                        // )
                                        handleDataChange(value)
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}

                        </Stack>
                    )}
                >
                    {Customer.map((Cust, key) =>
                        <MenuItem key={key} value={Cust.partnersId}>  {Cust.customerName} </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    useEffect(() => {
        fetchNewData('getOutstandingDiligentSearchReportData', 50, 1, completedPage);
        fetchCustomer();
    }, [])

    const handleMarkCompleted = () => {
        handleOpenCompletedDialog('true');
    }
    return (
        <><LocalizationProvider dateAdapter={AdapterDayjs}>

            <Box>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
   
            </Box>
          <Box sx={{ width: "100%" }}>
            <Box>
              <div >
                
                  <div className="col-lg-12    ">
                    <div
                      className=" d-flex  "
                      style={{ marginBottom: "28px" }} role="group"
                      aria-label="Basic example"
                    >
                      <React.Fragment>
    
                        <div className="col-6  text-center ">
                          <button type="button"
                            className={`btn-tab btn ${selectedButton === 1 ? "btn-Selected" : "btn-primary1"
                              }`}
                            {...a11yProps(8)}
                            onClick={() => handleChange(undefined, 1)}
                          >
                            Open Diligent Searches
                          </button>
                        </div>
                        <div className="col-6 text-center">
                            <button
                                type="button"
                                
                                className={`btn-tab btn ${selectedButton === 2 ? "btn-Selected" : "btn-primary"
                                }`}
                                {...a11yProps(5)}
                                onClick={() => handleChange(undefined, 2)}
                            >
                                Completed Diligent Searches
                            </button>
                        </div>
                        </React.Fragment>
                        </div>
                  </div>
                  </div>
                  </Box>
                  <Box>
                  <div className="col-lg-12    ">
                    <div
                      className=" d-flex  "
                      style={{ marginBottom: "28px" }} role="group"
                      aria-label="Basic example"
                    >
                    <Grid
                        container
                        spacing={3} // Adjust the spacing between grid items
                        className="filter-grid"
                        display="flex"
                        justifyContent="flex-end" // Align the buttons to the right
                        alignItems="right"
                    >
                    <React.Fragment>
                        <Grid item xl={2} sm={12} xs={12} minWidth={150}>
                            <FormControl  sx={{ width: '100%' }}>
                            <FMSelectCustomer name="customerIds" />
                            </FormControl>
                        </Grid>
                        {selectedButton === 1 && selectedRows?.length !== 0 && selectedRows?.length !== undefined ?
                            <Grid item minWidth={150} className="select-input-border d-flex">
                                
                                <button
                                    onClick={() => handleMarkCompleted()}
                                    className="active-btn-blue"
                                    style={{ width: '100%' }}
                                >
                                    Mark Completed
                                </button>
                            </Grid> : ''
                        }
                        <Grid item xl={2} sm={12} xs={12} minWidth={150} className="select-input-border d-flex">
                            <button
                                onClick={() => handleExportXlsxNew([])}
                                className="active-btn-blue"
                                style={{ width: '100%' }} // Ensure all buttons have equal width
                            >
                                Export
                            </button>
                        </Grid>
                        </React.Fragment>
                    </Grid>
                    </div>
                  </div>
                </Box>       
          </Box>

        </LocalizationProvider>
        {openCompletedDialog && <DiligentMarkCompleted
                open={!!openCompletedDialog}
                handleClose={handleCloseCompletedDialog}
                handleSubmit={handleSubmitCompletedDialog}
                selectedRows={selectedRows}
            />}
            <DiligentSearchList ReportModuleCL={ReportModule} fetchDataMain={fetchNewData} selectedButton={selectedButton} setSelectedRows={setSelectedRows} setCurrentPage={setCurrentPage} _currentPage={_currentPage} setPageSize={setPageSize} _pageSize={_pageSize} completedPage={completedPage} selectedRows={selectedRows} /></>
    );
}
